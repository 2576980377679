import pages from '@/router/routes'
export const realms = {
  freelancer: 'freelancer',
  agency: 'agency',
  admin: 'admin',
  finance: 'finance'
}

export const roles = {
  freelancer: 'default-roles-freelancer',
  accountManager: 'agency-manager',
  managingDirector: 'managing-director',
  admin: 'admin',
  finance: 'finance-user'
}

// GA tracking
export const roleNames = {
  'default-roles-freelancer': 'freelancer',
  'agency-manager': 'account manager',
  'managing-director': 'managing director',
  admin: 'admin',
  'finance-user': 'finance'
}

export const themes = {
  light: 'LIGHT',
  dark: 'DARK'
}

export const sideMenu = {
  admin: [
    {
      title: 'agencies',
      icon: 'business',
      link: pages.agencies.path
    },
    {
      title: 'users',
      icon: 'group',
      link: pages.users.path
    },
    {
      title: 'projects',
      icon: 'laptop',
      link: pages.projects.path
    },
    {
      title: 'bookings',
      icon: 'work_history',
      link: pages.bookings.path
    },
    {
      title: 'time-recording',
      icon: 'schedule',
      link: pages.timeRecording.path
    },
    {
      title: 'flat-rate-payments',
      icon: 'euro',
      link: pages.flatRatePayments.path
    },
    {
      title: 'credit-notes',
      icon: 'receipt_long',
      link: pages.creditNotes.path
    },
    {
      title: 'reports',
      icon: 'sticky_note_2',
      link: pages.reports.path
    }
  ],
  finance: [
    {
      title: 'agencies',
      icon: 'business',
      link: pages.agencies.path
    },
    {
      title: 'freelancers',
      icon: 'group',
      link: pages.freelancers.path
    },
    {
      title: 'projects',
      icon: 'laptop',
      link: pages.projects.path
    },
    {
      title: 'bookings',
      icon: 'work_history',
      link: pages.bookings.path
    },
    {
      title: 'time-recording',
      icon: 'schedule',
      link: pages.timeRecording.path
    },
    {
      title: 'flat-rate-payments',
      icon: 'euro',
      link: pages.flatRatePayments.path
    },
    {
      title: 'credit-notes',
      icon: 'receipt_long',
      link: pages.creditNotes.path
    },
    {
      title: 'reports',
      icon: 'sticky_note_2',
      link: pages.reports.path
    }
  ],
  managingDirector: [
    {
      title: 'freelancers',
      icon: 'group',
      link: pages.freelancers.path
    },
    {
      title: 'projects',
      icon: 'laptop',
      link: pages.projects.path
    },
    {
      title: 'bookings',
      icon: 'work_history',
      link: pages.bookings.path
    },
    {
      title: 'time-recording',
      icon: 'schedule',
      link: pages.timeRecording.path
    },
    {
      title: 'flat-rate-payments',
      icon: 'euro',
      link: pages.flatRatePayments.path
    },
    {
      title: 'credit-notes',
      icon: 'receipt_long',
      link: pages.creditNotes.path
    },
    {
      title: 'reports',
      icon: 'sticky_note_2',
      link: pages.reports.path
    }
  ],
  accountManager: [
    {
      title: 'freelancers',
      icon: 'group',
      link: pages.freelancers.path
    },
    {
      title: 'projects',
      icon: 'laptop',
      link: pages.projects.path
    },
    {
      title: 'bookings',
      icon: 'work_history',
      link: pages.bookings.path
    },
    {
      title: 'time-recording',
      icon: 'schedule',
      link: pages.timeRecording.path
    },
    {
      title: 'flat-rate-payments',
      icon: 'euro',
      link: pages.flatRatePayments.path
    },
    {
      title: 'credit-notes',
      icon: 'receipt_long',
      link: pages.creditNotes.path
    },
    {
      title: 'reports',
      icon: 'sticky_note_2',
      link: pages.reports.path
    }
  ],
  freelancer: [
    {
      title: 'projects',
      icon: 'laptop',
      link: pages.projects.path
    },
    {
      title: 'bookings',
      icon: 'work_history',
      link: pages.bookings.path
    },
    {
      title: 'time-recording',
      icon: 'schedule',
      link: pages.timeRecording.path
    },
    {
      title: 'credit-notes',
      icon: 'receipt_long',
      link: pages.creditNotes.path
    }
  ]
}

export const projectStatuses = {
  ongoing: 'ONGOING',
  paused: 'PAUSED',
  done: 'DONE'
}

export const projectRateTypes = {
  // daily: 'DAILY',
  hourly: 'HOURLY',
  flat: 'FLAT'
}

export const projectITRequirementTypes = {
  entryCard: 'ENTRY_CARD',
  emailAddress: 'EMAIL_ADDRESS',
  serverAccess: 'SERVER_ACCESS'
}

export const projectsFilterTabs = {
  bookmarked: 'BOOKMARKED',
  ongoing: 'ONGOING',
  paused: 'PAUSED',
  done: 'DONE'
}

export const projectBookingStatuses = {
  pending: 'PENDING',
  approved: 'APPROVED',
  approverDeclined: 'APPROVER_DECLINED',
  freelancerDeclined: 'FREELANCER_DECLINED',
  booked: 'BOOKED',
  finished: 'FINISHED',
  cancelled: 'CANCELLED'
}

export const bookingsFilterTabs = {
  open: 'OPEN',
  closed: 'CLOSED'
}

export const freelancerStatuses = {
  available: 'AVAILABLE',
  busy: 'BUSY'
}

export const freelancersFilterTabs = {
  bookmarked: 'BOOKMARKED',
  all: 'ALL',
  compare: 'COMPARE'
}

export const timeRecordingStatuses = {
  pending: 'PENDING',
  declined: 'DECLINED',
  approved: 'APPROVED',
  financeRejected: 'FINANCE_REJECTED',
  financeApproved: 'FINANCE_APPROVED',
  cancelled: 'CANCELLED'
}

export const flatRatePaymentStatuses = {
  pending: 'FINANCE_PENDING',
  rejected: 'FINANCE_REJECTED',
  approved: 'FINANCE_APPROVED',
  cancelled: 'DELETED'
}

export const timeRecordingFilterTabs = {
  all: 'ALL',
  pending: 'PENDING',
  declined: 'DECLINED',
  approved: 'APPROVED',
  financeApproved: 'FINANCE_APPROVED'
}

export const timeReviewFilterTabs = {
  pending: 'APPROVED',
  rejected: 'FINANCE_REJECTED',
  approved: 'FINANCE_APPROVED'
}

export const flatRatePaymentFilterTabs = {
  all: 'ALL',
  pending: 'FINANCE_PENDING',
  rejected: 'FINANCE_REJECTED',
  approved: 'FINANCE_APPROVED'
}

export const flatRateReviewFilterTabs = {
  pending: 'FINANCE_PENDING',
  rejected: 'FINANCE_REJECTED',
  approved: 'FINANCE_APPROVED'
}

export const creditNotesFilterTabs = {
  reviewed: 'REVIEWED',
  cancelled: 'CANCELLED'
}

export const replacementTypes = {
  vacation: 'VACATION',
  deactivation: 'DEACTIVATION',
  membershipRemoval: 'AGENCY_MEMBERSHIP_REMOVAL'
}

export const userRoles = [
  { value: 'freelancer', text: 'Freelancer' },
  { value: 'agency-manager', text: 'Account Manager' },
  { value: 'managing-director', text: 'Managing Director' },
  { value: 'admin', text: 'Admin' },
  { value: 'finance', text: 'Finance' }
]

export const userLanguages = [
  { value: 'english', text: 'English' },
  { value: 'german', text: 'German' }
]

export const userStatus = [
  { value: true, text: 'Active' },
  { value: false, text: 'In-Active' }
]

export const reportTopics = {
  creditNote: 'CREDIT_NOTE',
  booking: 'BOOKING_DETAILS',
  user: 'USER_LIST',
  agencyAmount: 'CREDIT_NOTE_AMOUNT',
  agencyBooking: 'AGENCY_BOOKING_AMOUNT',
  feedback: 'AGENCY_RATING'
}

export const reportStatuses = {
  creditNote: {
    approved: 'CREDIT_NOTE_APPROVED',
    cancelled: 'CREDIT_NOTE_CANCELLED'
  },
  costAllocation: {

  },
  booking: {
    pending: 'BOOKING_PENDING',
    booked: 'BOOKING_BOOKED',
    done: 'BOOKING_DONE',
    cancelled: 'BOOKING_CANCELLED'
  },
  user: {
    freelancer: 'USER_FREELANCER',
    accountManager: 'USER_ACCOUNT_MANAGER',
    managingDirector: 'USER_MANAGING_DIRECTOR',
    admin: 'USER_ADMIN',
    finance: 'USER_FINANCE'
  }
}

export const itemsPerPageOptions = [25, 50, 100]
export const itemsPerPage = 25

export const weekdayStart = 1 // Monday

export default {
  realms,
  roles,
  sideMenu,
  projectStatuses,
  projectRateTypes,
  projectsFilterTabs,
  projectITRequirementTypes,
  projectBookingStatuses,
  bookingsFilterTabs,
  timeRecordingFilterTabs,
  itemsPerPageOptions,
  itemsPerPage,
  creditNotesFilterTabs
}
