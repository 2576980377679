import Vue from 'vue'
import axios from 'axios'
import { itemsPerPage } from '@/helpers/constants'
import store from '@/store/index'

const state = () => ({
  isCreateAgencyDialogOpen: false,
  loading: false,
  currentPage: 1,
  agencies: [],
  perPageItems: itemsPerPage,
  sorting: {
    by: 'name',
    order: 'ASC'
  },
  total: null,
  agencyDetail: null
})

export default {
  namespaced: true,
  state,
  mutations: {
    resetStore ($state) {
      Object.assign($state, state())
    },
    resetAgencies (state) {
      state.currentPage = 1
      state.agencies = []
    },
    openCreateAgencyDialog (state) {
      state.isCreateAgencyDialogOpen = true
    },
    closeCreateAgencyDialog (state) {
      state.isCreateAgencyDialogOpen = false
    },
    setAgencies (state, data) {
      state.agencies = data
    },
    toggleLoading (state, status) {
      state.loading = status
    },
    setPerPageItems (state, val) {
      state.perPageItems = val
    },
    setPaginationPage (state, data) {
      state.currentPage = data
    },
    updateSort (state, data) {
      state.sorting = data
    },
    setTotal (state, data) {
      state.total = data
    },
    setAgencyDetail (state, data) {
      state.agencyDetail = data
    },
    toggleStatus (state, id) {
      const agencyIndex = state.agencies.findIndex(obj => obj.id === id)
      state.agencies[agencyIndex].is_active = !state.agencies[agencyIndex].is_active
    },
    updateAgencyBookingFreeze (state, data) {
      if (state.agencyDetail?.id === data.agency_id) {
        state.agencyDetail.is_booking_freezed = data.active
      }
    },
    addNotificationMember ({ agencyDetail }, data) {
      if (agencyDetail?.id) {
        if (!agencyDetail.notification_members) {
          Vue.set(agencyDetail, 'notification_members', [])
        }
        agencyDetail.notification_members.push(data)
      }
    },
    removeNotificationMember ({ agencyDetail }, id) {
      if (agencyDetail?.id) {
        const index = agencyDetail.notification_members.findIndex(item => id === item.id)
        if (index !== -1) {
          agencyDetail.notification_members.splice(index, 1)
        }
      }
    }
  },
  actions: {
    openCreateAgencyDialog ({ commit, dispatch }) {
      commit('openCreateAgencyDialog', true)
    },
    closeCreateAgencyDialog ({ commit }) {
      commit('closeCreateAgencyDialog')
    },
    createNewAgency ({ commit, dispatch }, form) {
      return new Promise((resolve, reject) => {
        const params = new FormData()

        Object.keys(form).forEach(key => {
          const val = form[key]
          params.append(key, Array.isArray(val) || key === 'invoice_address' ? JSON.stringify(val) : val)
        })

        const url = `${process.env.VUE_APP_AGENCY_API_URL}${form.id ? 'agency/update' : 'create-agency'}`
        axios[form.id ? 'put' : 'post'](url, params,
          {
            headers: { 'content-type': 'multipart/form-data' }
          })
          .then(({ data }) => {
            if (data.status === 'success') {
              if (store.getters['auth/isManagingDirector']) {
                store.dispatch('agency/loadProfile')
              } else {
                dispatch('loadAgencies')
              }
            }
            resolve(data)
          })
          .catch(() => {
            reject(new Error('Error creating new agency'))
          })
      })
    },
    loadAgencies ({ state, commit, rootGetters }, search) {
      return new Promise((resolve, reject) => {
        const params = new URLSearchParams()
        const isSearch = typeof search !== 'undefined'
        params.append('items', state.perPageItems)
        const { by, order } = state.sorting
        params.append('sortBy', by)
        params.append('sort', order)
        params.append('page', state.currentPage)
        if (isSearch) {
          params.append('keyword[]', search)
        } else {
          const filters = rootGetters['agenciesFilter/finalFilter']
          Object.keys(filters).forEach(key => {
            const val = filters[key]
            if (Array.isArray(val)) {
              val.forEach((item, index) => {
                params.append(`${key}[]`, item)
              })
            } else {
              params.append(key, val)
            }
          })

          commit('toggleLoading', true)
        }
        axios.get(`${process.env.VUE_APP_AGENCY_API_URL}admin/agency-list?${params.toString()}`)
          .then(({ data }) => {
            if (data.status === 'success') {
              const agencies = data.data.map(item => ({
                ...item,
                is_active: !!+item.is_active,
                is_profile_complete: !!+item.is_profile_complete
              }))
              if (!isSearch) {
                commit('setAgencies', agencies)
                commit('setTotal', data.total)
                commit('toggleLoading', false)
              }
              resolve(agencies)
            } else {
              reject(new Error('Error loading agencies'))
            }
          })
          .catch(() => {
            reject(new Error('Error loading agencies'))
          })
      })
    },
    reloadAgencies ({ commit, dispatch }) {
      commit('resetAgencies')
      dispatch('loadAgencies')
    },
    loadAgencyDetail ({ commit }, agencyId) {
      return new Promise((resolve, reject) => {
        commit('setAgencyDetail', null)

        axios.get(`${process.env.VUE_APP_AGENCY_API_URL}agency-details/${agencyId}`)
          .then(({ data }) => {
            if (data.status === 'success') {
              commit('setAgencyDetail', data.data)
              resolve(data.data)
            } else {
              reject(new Error('Error loading agency detail'))
            }
          })
          .catch(() => {
            reject(new Error('Error loading agency detail'))
          })
      })
    },
    async updateAgencies ({ commit, dispatch }, option) {
      await commit('setPaginationPage', option.page)
      await commit('setPerPageItems', option.itemsPerPage)
      await dispatch('loadAgencies')
    },
    async sortAgencies ({ commit, dispatch }, sort) {
      await commit('updateSort', sort)
      await dispatch('loadAgencies')
    },
    deactivateAgency ({ commit, dispatch }, id) {
      return new Promise((resolve, reject) => {
        axios.patch(`${process.env.VUE_APP_AGENCY_API_URL}admin/deactivate-agency/${id}`)
          .then(({ data }) => {
            if (data.status === 'success') {
              commit('toggleStatus', id)
              resolve(data)
            } else {
              reject(new Error('Error deactivating agency'))
            }
          })
          .catch(() => {
            reject(new Error('Error deactivating agency'))
          })
      })
    },
    freezeAgencyBooking ({ commit }, form) {
      return new Promise((resolve, reject) => {
        const params = new FormData()
        Object.keys(form).forEach(key => {
          params.append(key, form[key])
        })

        axios.put(`${process.env.VUE_APP_AGENCY_API_URL}booking/freeze`, params,
          {
            headers: { 'content-type': 'application/x-www-form-urlencoded' }
          })
          .then(({ data }) => {
            if (data.status === 'success') {
              commit('updateAgencyBookingFreeze', form)
              resolve()
            } else {
              reject(new Error('Error in agency booking freeze API'))
            }
          })
          .catch(() => {
            reject(new Error('Error in agency booking freeze API'))
          })
      })
    },
    increaseAgencyBudget ({ commit }, form) {
      return new Promise((resolve, reject) => {
        const params = new FormData()
        Object.keys(form).forEach(key => {
          params.append(key, form[key])
        })

        axios.post(`${process.env.VUE_APP_AGENCY_API_URL}increase/budget`, params,
          {
            headers: { 'content-type': 'application/x-www-form-urlencoded' }
          })
          .then(({ data }) => {
            if (data.status === 'success') {
              resolve()
            } else {
              reject(new Error('Error in agency budget increase API'))
            }
          })
          .catch(() => {
            reject(new Error('Error in agency budget increase API'))
          })
      })
    },
    addNotificationMember ({ commit }, form) {
      return new Promise((resolve, reject) => {
        const { id, mail, displayName, image } = form
        commit('addNotificationMember', { id, email: mail, displayName, image })
        const params = new FormData()
        params.append('email', mail)
        params.append('agency_id', form.agency_id)

        axios.post(`${process.env.VUE_APP_AGENCY_API_URL}notification/members/add`, params,
          {
            headers: { 'content-type': 'application/x-www-form-urlencoded' }
          })
          .then(({ data }) => {
            commit('removeNotificationMember', id)
            if (data.status === 'success') {
              commit('addNotificationMember', {
                email: mail,
                image,
                displayName,
                id: parseInt(data.data[0].id)
              })
              resolve()
            } else {
              reject(new Error('Error adding notification member'))
            }
          })
          .catch(() => {
            commit('removeNotificationMember', id)
            reject(new Error('Error adding notification member'))
          })
      })
    },
    removeNotificationMember ({ commit }, id) {
      return new Promise((resolve, reject) => {
        commit('removeNotificationMember', id)

        axios.delete(`${process.env.VUE_APP_AGENCY_API_URL}notification/members/remove/${id}`)
          .then(({ data }) => {
            resolve(data)
          })
          .catch(() => {
            reject(new Error('Error removing notification member'))
          })
      })
    },
    loadUserDetail ({ commit }, userId) {
      return new Promise((resolve, reject) => {
        const params = new URLSearchParams()
        params.append('user_id', userId)
        axios.get(`${process.env.VUE_APP_AGENCY_API_URL}user?${params.toString()}`)
          .then(({ data }) => {
            if (data.status === 'success') {
              resolve(data.data)
            } else {
              reject(new Error('Error loading user detail'))
            }
          })
          .catch(() => {
            reject(new Error('Error loading user detail'))
          })
      })
    }
  }
}
