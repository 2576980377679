import Vue from 'vue'
import VueRouter from 'vue-router'
import i18n from '@/plugins/i18n'
import store from '@/store'
import pages from './routes'
// import storage from '@/helpers/storage'

import { realms, roles } from '@/helpers/constants'

const { admin, finance, managingDirector, accountManager, freelancer } = roles

Vue.use(VueRouter)

const validateAccess = (allowedRoles = [], validateProfileCompletion = true) => {
  return (to, from, next) => {
    const { name } = to
    const { realm, activeGroup, data: { groups } } = store.state.auth
    const { agency, freelancer } = realms

    if (groups?.length > 1 && !activeGroup && name !== pages.agencySelection.name) {
      return next(pages.agencySelection.path)
    }

    const isProfileComplete = [agency, freelancer].includes(realm) ? store.getters[`${realm}/isProfileComplete`] : true
    const agencyOnboardingPages = [pages.agencyWelcome.name, pages.agencyOnboarding.name]
    const freelancerOnboardingPages = [pages.freelancerWelcome.name, pages.freelancerOnboarding.name]

    if (validateProfileCompletion && !isProfileComplete) {
      if (realm === agency && !agencyOnboardingPages.includes(name)) {
        return next(pages.agencyWelcome.path)
      }

      if (realm === freelancer && !freelancerOnboardingPages.includes(name)) {
        return next(pages.freelancerWelcome.path)
      }
    } else if ([...agencyOnboardingPages, ...freelancerOnboardingPages].includes(name)) {
      return next(pages.dashboard.path)
    }

    const { data: { roles = [] } = {} } = store.state.auth

    if (allowedRoles.length) {
      let isAllowed = false
      for (const i in allowedRoles) {
        if (roles.includes(allowedRoles[i])) {
          isAllowed = true
          break
        }
      }

      if (!isAllowed) {
        return next(pages.dashboard.path)
      }
    }

    next()
  }
}

const redirectIfLoggedIn = (to, from, next) => {
  const isLoggedIn = !!store.state.auth?.accessToken
  if (isLoggedIn) {
    return next(pages.home.path)
  }
  const { name, query } = to
  if ((name === pages.agencyLogin.name && !['login', 'register'].includes(query.type)) ||
    (name === pages.freelancerRegister.name && !query.t)
  ) {
    return next(pages.login.path)
  }

  next()
}

const routes = [
  {
    path: '/maintenance',
    // path: '/*',
    name: 'Maintenance',
    component: () => import(/* webpackChunkName: "design-system" */ '../views/Maintenance.vue')
  },
  {
    path: pages.home.path,
    name: pages.home.name,
    redirect: pages.dashboard.path,
    component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue'),
    meta: { requiresAuth: true },
    beforeEnter: async (to, from, next) => {
      const isLoggedIn = !!store.state.auth?.accessToken

      if (!isLoggedIn) {
        return next(pages.login.path)
      }

      // const { path, name, params } = to

      // const activePage = await storage.get('activePage')

      // const names = ['Login', 'Register', null]

      // if (activePage && activePage !== path && names.includes(from.name) && !names.includes(name)) {
      //   return next(activePage)
      // }

      // if (!Object.keys(params).length && !names.includes(name)) {
      //   await storage.set({ activePage: to.path })
      // }

      return next()
    },
    children: [
      {
        path: pages.agencyWelcome.path,
        name: pages.agencyWelcome.name,
        component: () => import(/* webpackChunkName: "agency-welcome" */ '@/views/auth/AgencyWelcome.vue'),
        meta: { i18n: 'onboarding/agency', isCustomLayout: true },
        beforeEnter: validateAccess([managingDirector])
      },
      {
        path: pages.agencyOnboarding.path,
        name: pages.agencyOnboarding.name,
        component: () => import(/* webpackChunkName: "agency-onboarding" */ '@/views/auth/AgencyOnboarding.vue'),
        meta: { i18n: 'onboarding/agency', isCustomLayout: true },
        beforeEnter: validateAccess([managingDirector])
      },
      {
        path: pages.agencyProfileEdit.path,
        name: pages.agencyProfileEdit.name,
        component: () => import(/* webpackChunkName: "agency-onboarding" */ '@/views/auth/AgencyOnboarding.vue'),
        meta: { i18n: 'onboarding/agency', isCustomLayout: true },
        beforeEnter: validateAccess([managingDirector])
      },
      {
        path: pages.freelancerWelcome.path,
        name: pages.freelancerWelcome.name,
        component: () => import(/* webpackChunkName: "freelancer-welcome" */ '@/views/auth/FreelancerWelcome.vue'),
        meta: { i18n: 'onboarding/freelancer', isCustomLayout: true },
        beforeEnter: validateAccess([freelancer])
      },
      {
        path: pages.freelancerOnboarding.path,
        name: pages.freelancerOnboarding.name,
        component: () => import(/* webpackChunkName: "freelancer-onboarding" */ '@/views/auth/FreelancerOnboarding.vue'),
        meta: { i18n: 'onboarding/freelancer', isCustomLayout: true },
        beforeEnter: validateAccess([freelancer])
      },
      {
        path: pages.freelancerProfileEdit.path,
        name: pages.freelancerProfileEdit.name,
        component: () => import(/* webpackChunkName: "freelancer-onboarding" */ '@/views/auth/FreelancerOnboarding.vue'),
        meta: { i18n: 'onboarding/freelancer', isCustomLayout: true },
        beforeEnter: validateAccess([freelancer])
      },
      {
        path: pages.dashboard.path,
        name: pages.dashboard.name,
        component: () => import(/* webpackChunkName: "dashboard" */ '@/views/Dashboard.vue'),
        meta: { title: 'dashboard' },
        beforeEnter: validateAccess()
      },
      {
        path: pages.projects.path,
        name: pages.projects.name,
        component: () => import(/* webpackChunkName: "projects" */ '@/views/Projects.vue'),
        meta: { title: 'projects' },
        beforeEnter: validateAccess()
      },
      {
        path: pages.projectDetail.path,
        name: pages.projectDetail.name,
        component: () => import(/* webpackChunkName: "project-single" */ '@/views/ProjectSingle.vue'),
        meta: { title: 'projects', back: true, shrinkPadding: true },
        beforeEnter: validateAccess()
      },
      {
        path: pages.bookings.path,
        name: pages.bookings.name,
        component: () => import(/* webpackChunkName: "bookings" */ '@/views/Bookings.vue'),
        meta: { title: 'bookings' },
        beforeEnter: validateAccess()
      },
      {
        path: pages.freelancers.path,
        name: pages.freelancers.name,
        component: () => import(/* webpackChunkName: "freelancers" */ '@/views/Freelancers.vue'),
        meta: { title: 'freelancers' },
        beforeEnter: validateAccess([managingDirector, accountManager, finance])
      },
      {
        path: pages.freelancerDetail.path,
        name: pages.freelancerDetail.name,
        component: () => import(/* webpackChunkName: "freelancer-single" */ '@/views/FreelancerSingle.vue'),
        meta: { title: 'freelancers', back: true, shrinkPadding: true, i18n: 'settings' },
        beforeEnter: validateAccess([admin, finance, managingDirector, accountManager])
      },
      {
        path: pages.agencies.path,
        name: pages.agencies.name,
        component: () => import(/* webpackChunkName: "freelancers" */ '@/views/Agencies.vue'),
        meta: { title: 'agencies' },
        beforeEnter: validateAccess([admin, finance])
      },
      {
        path: pages.agencyDetail.path,
        name: pages.agencyDetail.name,
        component: () => import(/* webpackChunkName: "agency-single" */ '@/views/AgencySingle.vue'),
        meta: { title: 'agencies', back: true, shrinkPadding: true },
        beforeEnter: validateAccess()
      },
      {
        path: pages.agencyEditAdmin.path,
        name: pages.agencyEditAdmin.name,
        component: () => import(/* webpackChunkName: "agency-single" */ '@/views/auth/AgencyOnboarding.vue'),
        meta: { i18n: 'onboarding/agency', isCustomLayout: true },
        beforeEnter: validateAccess([admin])
      },
      {
        path: pages.timeRecording.path,
        name: pages.timeRecording.name,
        component: () => import(/* webpackChunkName: "time-recording" */ '@/views/TimeRecordings.vue'),
        meta: { title: 'time-recording' },
        beforeEnter: validateAccess()
      },
      {
        path: pages.flatRatePayments.path,
        name: pages.flatRatePayments.name,
        component: () => import(/* webpackChunkName: "flat-rate-payments" */ '@/views/FlatRatePayments.vue'),
        meta: { title: 'flat-rate-payments' },
        beforeEnter: validateAccess([admin, finance, managingDirector, accountManager])
      },
      {
        path: pages.creditNotes.path,
        name: pages.creditNotes.name,
        component: () => import(/* webpackChunkName: "credit-notes" */ '@/views/CreditNotes.vue'),
        meta: { title: 'credit-notes' },
        beforeEnter: validateAccess()
      },
      {
        path: pages.users.path,
        name: pages.users.name,
        component: () => import(/* webpackChunkName: "users" */ '@/views/Users.vue'),
        meta: { title: 'users' },
        beforeEnter: validateAccess([admin])
      },
      {
        path: pages.userSettings.path,
        name: pages.userSettings.name,
        component: () => import(/* webpackChunkName: "user-settings" */ '@/views/UserSettings.vue'),
        meta: { title: 'user-settings', back: true, i18n: 'settings' },
        beforeEnter: validateAccess([admin])
      },
      {
        path: pages.profile.path,
        name: pages.profile.name,
        component: () => import(/* webpackChunkName: "profile" */ '../views/Profile.vue'),
        meta: { title: 'profile', back: true },
        beforeEnter: validateAccess([managingDirector, accountManager, freelancer])
      },
      {
        path: pages.settings.path,
        name: pages.settings.name,
        component: () => import(/* webpackChunkName: "settings" */ '../views/Settings.vue'),
        meta: { title: 'settings', back: true, i18n: 'settings' },
        beforeEnter: validateAccess()
      },
      {
        path: pages.reports.path,
        name: pages.reports.name,
        component: () => import(/* webpackChunkName: "reports" */ '../views/Reports.vue'),
        meta: { title: 'reports' },
        beforeEnter: validateAccess([admin, finance, managingDirector, accountManager])
      }
    ]
  },
  {
    path: pages.agencyLogin.path,
    name: pages.agencyLogin.name,
    component: () => import(/* webpackChunkName: "agency-login" */ '@/views/auth/LoginAgency'),
    beforeEnter: redirectIfLoggedIn
  },
  {
    path: pages.agencyRegister.path,
    name: pages.agencyRegister.name,
    component: () => import(/* webpackChunkName: "register-agency-user" */ '@/views/auth/RegisterAgencyUser.vue'),
    beforeEnter: redirectIfLoggedIn
  },
  {
    path: pages.agencySelection.path,
    name: pages.agencySelection.name,
    component: () => import(/* webpackChunkName: "agency-selection" */ '@/views/AgencySelection.vue'),
    beforeEnter: (to, from, next) => {
      const isLoggedIn = !!store.state.auth?.accessToken
      if (!isLoggedIn) {
        return next(pages.login.path)
      }
      return validateAccess([managingDirector, accountManager], false)(to, from, next)
    }
  },
  {
    path: pages.login.path,
    name: pages.login.name,
    component: () => import(/* webpackChunkName: "login" */ '@/views/auth/Login.vue'),
    beforeEnter: redirectIfLoggedIn
  },
  {
    path: pages.adminLogin.path,
    name: pages.adminLogin.name,
    component: () => import(/* webpackChunkName: "admin-login" */ '@/views/auth/AdminLogin.vue'),
    beforeEnter: redirectIfLoggedIn
  },
  {
    path: pages.freelancerResetPassword.path,
    name: pages.freelancerResetPassword.name,
    component: () => import(/* webpackChunkName: "reset-password" */ '@/views/auth/ResetPassword.vue'),
    beforeEnter: redirectIfLoggedIn
  },
  {
    path: pages.freelancerRegister.path,
    name: pages.freelancerRegister.name,
    component: () => import(/* webpackChunkName: "register" */ '@/views/auth/Register.vue'),
    beforeEnter: redirectIfLoggedIn
  },
  {
    path: pages.userRegister.path,
    name: pages.userRegister.name,
    component: () => import(/* webpackChunkName: "register" */ '@/views/auth/RegisterUser.vue'),
    beforeEnter: redirectIfLoggedIn
  },
  {
    path: pages.about.path,
    name: pages.about.name,
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/design-system',
    name: 'DesignSystem',
    component: () => import(/* webpackChunkName: "design-system" */ '../views/DesignSystem.vue')
  },
  {
    path: pages.redirectTo.path,
    name: pages.redirectTo.name,
    component: () => import(/* webpackChunkName: "redirect-to" */ '../views/RedirectTo.vue')
  }
]

// const routes = [...globalRoutes, ...agencyRoutes, ...freelancerRoutes]
const router = new VueRouter({
  // mode: 'history',
  routes
})

const loadedTranslations = []

function loadLanguageAsync (path) {
  // If the translations was already loaded
  if (loadedTranslations.includes(path)) {
    return Promise.resolve()
  }

  // If the translations hasn't been loaded yet
  return import(/* webpackChunkName: "lazy-translations-[request]" */ `@/locales/lazy/${path}.json`)
    .then(translations => {
      Object.keys(translations)
        .forEach(lang => i18n.mergeLocaleMessage(lang, translations[lang]))
      loadedTranslations.push(path)
      Promise.resolve()
    })
}

router.beforeEach(async (to, from, next) => {
  Vue.$log.debug(to.path)

  if (to.meta.i18n) {
    await loadLanguageAsync(to.meta.i18n)
  }

  return next()
})

export default router
