export default {
  inviteAdmin: 'invite-admin',
  inviteFinance: 'invite-finance',
  inviteMD: 'invite-managing-director',
  inviteACM: 'invite-account-manager',
  inviteFLC: 'invite-freelancer',
  updateFLCProfile: 'update-freelancer-profile',
  updateAgencyProfile: 'update-agency-profile',
  manageProject: 'manage-project',
  projectOwner: 'owner-project',
  approveBooking: 'approve-booking',
  authorizeFlatPayment: 'authorize-flatrate-payment',
  manageAgency: 'agency-manage',
  manageAgencyBudget: 'manage-agency-budget',
  vacationReplacement: 'agency-user-vacation-replacement',
  updateBudgetNotificationList: 'update-agency-budget-notification-list',
  updateAgencyUserRole: 'update-agency-user-role',
  deactivateAgencyUser: 'deactivate-agency-user',
  closeFinancialYear: 'close-financial-year',
  deleteFLCProfile: 'delete-freelancer-profile'
}
