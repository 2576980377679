import Vue from 'vue'
import axios from 'axios'
import uniqBy from 'lodash/uniqBy'
import compact from 'lodash/compact'
import { realms, themes, roles } from '@/helpers/constants'
import convertToFormData from '@/helpers/convertToFormData'
import store from '@/store/index'

let loadingUserGroups = false
let loadingColleagues = false

const state = () => ({
  profile: {},
  specialities: [],
  clients: [],
  employees: [],
  userGroups: [],
  multiAgencyColleagues: [],
  topConsumptions: null,
  seeTimeReview: false,
  seeFlatRateReview: false
})

export default {
  namespaced: true,
  state,
  getters: {
    isProfileComplete: ({ profile }) => profile.is_profile_complete,
    clients: ({ profile }) => profile.clients || [],
    approvers: ({ profile }) => (profile.users || []).filter(({ role }) => role === roles.managingDirector),
    hasMaconomyIntegration: ({ profile }) => profile?.is_maconomy_data_exchange_required,
    isBookingApprovalRequired: ({ profile }) => profile?.is_booking_approval_required,
    isBookingFreezed: ({ profile }) => profile?.is_booking_freezed,
    isAgencyActive: ({ profile }) => profile?.is_active,
    seeTimeReview: ({ seeTimeReview }) => {
      if (store.getters['auth/isFinance']) {
        return true
      }
      return store.getters['auth/isManagingDirector'] ? seeTimeReview : false
    },
    seeFlatRateReview: ({ seeFlatRateReview }) => {
      if (store.getters['auth/isFinance']) {
        return true
      }
      return store.getters['auth/isManagingDirector'] ? seeFlatRateReview : false
    }
  },
  mutations: {
    toggleSeeTimeReview (state, status) {
      state.seeTimeReview = status
    },
    toggleSeeFlatRateReview (state, status) {
      state.seeFlatRateReview = status
    },
    resetStore ($state) {
      Object.assign($state, state())
    },
    setProfileData (state, data) {
      state.profile = {
        ...state.profile,
        ...data,
        is_active: !!+data.is_active,
        is_booking_approval_required: !!+data.is_booking_approval_required,
        is_profile_complete: !!+data.is_profile_complete,
        is_maconomy_data_exchange_required: !!data.is_maconomy_data_exchange_required,
        is_booking_freezed: !!data.is_booking_freezed
      }
      if (typeof state.profile.confirmed_bookings === 'undefined') {
        state.profile.confirmed_bookings = 0
        state.profile.pending_bookings = 0
        state.profile.completed_bookings = 0
      }
    },
    addClientToAgency (state, client) {
      state.profile.clients.push(client)
    },
    setSpecialities (state, value) {
      state.specialities = value
    },
    setClients (state, data) {
      state.clients = uniqBy([...state.clients, ...data], 'id')
    },
    resetClients (state) {
      state.clients = []
    },
    setEmployees (state, data) {
      state.employees = uniqBy([...state.employees, ...data], 'id')
    },
    resetEmployees (state) {
      state.employees = []
    },
    addReplacementToProfile (state, data) {
      if (!state.profile.replacement_users) {
        Vue.set(state.profile, 'replacement_users', [])
      }
      state.profile.replacement_users = [...state.profile.replacement_users, ...data]
    },
    removeReplacementFromProfile (state, { id, type }) {
      const index = state.profile.replacement_users.findIndex(item => item.replacement_id === id && item.type === type)
      state.profile.replacement_users.splice(index, 1)
    },
    updateUserRole (state, data) {
      const index = state.profile.users.findIndex(item => item.id === data.user_id)
      state.profile.users[index].role = data.role
    },
    setGroupsList (state, data) {
      state.userGroups = data
    },
    updateFinancialYearStatus (state, data) {
      if (state.profile.financialYear) {
        state.profile.financialYear.is_closed = data.is_closed
      } else {
        Vue.set(state.profile, 'financialYear', data)
      }
    },
    setMultiAgencyColleagues (state, data) {
      state.multiAgencyColleagues = data
    },
    setTopConsumptions (state, data) {
      state.topConsumptions = data
    }
  },
  actions: {
    loadSpecialities ({ state, commit }) {
      if (state.specialities.length) {
        return
      }
      axios.get(`${process.env.VUE_APP_AGENCY_API_URL}specialities`)
        .then(({ data }) => {
          if (data.status === 'success') {
            commit('setSpecialities', data.data)
          }
        })
    },
    toggleSeeTimeReview ({ commit, dispatch }, status) {
      commit('toggleSeeTimeReview', status)
    },
    toggleSeeFlatRateReview ({ commit, dispatch }, status) {
      commit('toggleSeeFlatRateReview', status)
    },
    loadClients ({ commit }, search) {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_AGENCY_API_URL}clients?keyword=${search.trim()}`)
          .then(({ data: { status, data } }) => {
            if (status === 'success') {
              const result = data.data.map(({ id, client: name }) => ({ id, name }))
              commit('setClients', result)
              resolve(result)
            } else {
              reject(new Error('Error loading clients'))
            }
          })
          .catch(() => {
            reject(new Error('Error loading clients'))
          })
      })
    },
    createClient ({ commit }, value) {
      return new Promise((resolve, reject) => {
        const params = new FormData()
        params.append('name', value)

        axios.post(`${process.env.VUE_APP_AGENCY_API_URL}clients`, params,
          {
            headers: { 'content-type': 'application/x-www-form-urlencoded' }
          })
          .then(({ data: { status, data } }) => {
            if (status === 'success') {
              const client = { id: data.id, name: data.client }
              commit('setClients', [client])
              resolve(client)
            } else {
              reject(new Error('Error saving client'))
            }
          })
          .catch(() => {
            reject(new Error('Error saving client'))
          })
      })
    },
    loadEmployees ({ commit }, search) {
      return new Promise((resolve, reject) => {
        const params = new FormData()
        params.append('keyword', search)

        axios.post(`${process.env.VUE_APP_AGENCY_API_URL}azure/search`, params,
          {
            headers: { 'content-type': 'application/x-www-form-urlencoded' }
          })
          .then(({ data }) => {
            if (data.status === 'success') {
              commit('setEmployees', data.data)
              resolve()
            } else {
              reject(new Error('Error loading employees'))
            }
          })
          .catch(() => {
            reject(new Error('Error loading employees'))
          })
      })
    },
    saveProfile ({ commit, rootGetters }, form) {
      return new Promise((resolve, reject) => {
        const params = new FormData()

        Object.keys(form).forEach(key => {
          let val = form[key]
          if (Array.isArray(val)) {
            val = compact(val)
            params.append(key, JSON.stringify(val))
          } else if (val instanceof Object) {
            params.append('invoice_address', JSON.stringify(val))
          } else {
            params.append(key, val)
          }
        })

        const isAdmin = rootGetters['auth/isAdmin']
        const path = isAdmin ? 'admin/agency/onboard' : 'profile'

        axios.put(`${process.env.VUE_APP_AGENCY_API_URL}${path}`, params,
          {
            headers: { 'content-type': 'application/x-www-form-urlencoded' }
          })
          .then(({ data }) => {
            if (data.status === 'success') {
              if (!isAdmin) {
                commit('setProfileData', data.data)

                const {
                  street: agencyAddressStreet = '',
                  no: agencyAddressNo = '',
                  zip: agencyAddressZip = '',
                  city: agencyAddressCity = '',
                  countryCode: agencyAddressCountry = ''
                } = data.data.invoice_address

                commit('auth/UPDATE_AUTH_DATA', {
                  agencyAddressStreet,
                  agencyAddressNo,
                  agencyAddressZip,
                  agencyAddressCity,
                  agencyAddressCountry
                }, { root: true })

                commit('resetClients')
              }
              resolve()
            } else {
              reject(new Error('Error saving profile'))
            }
          })
          .catch(() => {
            reject(new Error('Error saving profile'))
          })
      })
    },
    loadProfile ({ commit, dispatch, rootState }) {
      return new Promise((resolve, reject) => {
        let path = process.env.VUE_APP_AGENCY_API_URL
        switch (rootState.auth.realm) {
        case realms.admin:
          path += 'admin/'
          break
        case realms.finance:
          path += 'finance/'
          break
        }
        axios.get(`${path}profile`)
          .then(({ data }) => {
            if (data.status === 'success') {
              commit('setProfileData', data.data)
              let {
                first_name: firstName = '',
                last_name: lastName = '',
                user_profile_image: thumb,
                unread_to_do_count: todoCount = 0,
                unread_info_count: infoCount = 0,
                unread_message_count: messageCount = 0,
                agency_address_street: agencyAddressStreet = '',
                agency_address_no: agencyAddressNo = '',
                agency_address_zip: agencyAddressZip = '',
                agency_address_city: agencyAddressCity = '',
                agency_address_country: agencyAddressCountry = '',
                users = []
              } = data.data

              if (rootState.auth.realm === realms.agency) {
                const user = users.find(({ id }) => id === rootState.auth?.data?.id)
                if (user) {
                  firstName = user.first_name
                  lastName = user.last_name
                }
              }

              if (data?.data?.invoice_address) {
                agencyAddressStreet = data.data.invoice_address.street
                agencyAddressNo = data.data.invoice_address.no
                agencyAddressZip = data.data.invoice_address.zip
                agencyAddressCity = data.data.invoice_address.city
                agencyAddressCountry = data.data.invoice_address.countryCode
              }

              commit('auth/SET_PROFILE_IMAGE', thumb, { root: true })
              commit('notification/updateNotificationCount', { todoCount, infoCount, messageCount }, { root: true })
              commit('auth/UPDATE_AUTH_DATA', {
                firstName,
                lastName,
                agencyAddressStreet,
                agencyAddressNo,
                agencyAddressZip,
                agencyAddressCity,
                agencyAddressCountry
              }, { root: true })

              if (!data?.data?.settings) {
                dispatch('saveSettings')
              } else {
                const { theme, account_language: language } = data.data.settings
                dispatch('theme/compareSettings', theme, { root: true })
                dispatch('lang/compareSettings', language, { root: true })
              }
              resolve({})
            } else {
              resolve({ message: 'responseMessages.error.unknown' })
            }
          })
          .catch(error => {
            console.log('agency loadProfile error', error)
            if (error?.response?.status === 403) {
              dispatch('auth/logout', null, { root: true })
              resolve({ message: 'responseMessages.error.uninvited' })
            } else {
              resolve({ message: 'responseMessages.error.unknown' })
            }
          })
      })
    },
    saveSettings ({ commit, rootState }) {
      return new Promise((resolve, reject) => {
        const { isDark = false } = rootState.theme
        const { locale } = rootState.lang
        const params = new FormData()
        params.append('theme', isDark ? themes.dark : themes.light)
        params.append('account_language', locale)

        let path = `${process.env.VUE_APP_AGENCY_API_URL}settings`
        switch (rootState.auth.realm) {
        case realms.admin:
          path += '/admin'
          break
        case realms.finance:
          path += '/finance'
          break
        }

        axios.put(path, params,
          {
            headers: { 'content-type': 'application/x-www-form-urlencoded' }
          })
          .then(({ data }) => { })
          .catch(() => {
            reject(new Error('Error saving settings'))
          })
      })
    },
    inviteUsers ({ commit }, form) {
      return new Promise((resolve, reject) => {
        const params = new FormData()
        Object.keys(form).forEach(key => {
          const val = form[key]
          params.append(key, Array.isArray(val) ? JSON.stringify(val) : val)
        })

        axios.post(`${process.env.VUE_APP_AGENCY_API_URL}users/invite`, params,
          {
            headers: { 'content-type': 'application/x-www-form-urlencoded' }
          })
          .then(({ data }) => {
            resolve(data)
          })
          .catch(() => {
            resolve({})
          })
      })
    },
    saveReplacement ({ commit, rootGetters }, form) {
      return new Promise((resolve, reject) => {
        const params = convertToFormData(form)

        axios.post(`${process.env.VUE_APP_AGENCY_API_URL}users/replace`, params,
          {
            headers: { 'content-type': 'application/x-www-form-urlencoded' }
          })
          .then(({ data }) => {
            if (data.status === 'success') {
              const items = (data.data || []).map(item => ({ ...item, replacement_id: item.id }))
              if (rootGetters['auth/isAgency']) {
                commit('addReplacementToProfile', items)
              }
              resolve(items)
            } else {
              reject(new Error('Error saving replacement'))
            }
          })
          .catch(() => {
            reject(new Error('Error saving replacement'))
          })
      })
    },
    removeReplacement ({ commit, rootGetters }, { type, replacement_id: id }) {
      return new Promise((resolve, reject) => {
        const params = new URLSearchParams()
        params.append('id', id)
        axios.delete(`${process.env.VUE_APP_AGENCY_API_URL}users/replace?${params.toString()}`)
          .then(({ data }) => {
            if (data.status === 'success') {
              if (rootGetters['auth/isAgency']) {
                commit('removeReplacementFromProfile', { id, type })
              }
              resolve()
            } else {
              reject(new Error('Error removing replacement'))
            }
          })
          .catch(() => {
            reject(new Error('Error removing replacement'))
          })
      })
    },
    updateFinancialYearSettings ({ commit }, form) {
      return new Promise((resolve, reject) => {
        const params = new FormData()
        Object.keys(form).forEach(key => {
          params.append(key, form[key])
        })

        axios.post(`${process.env.VUE_APP_AGENCY_API_URL}financial-year/close`, params,
          {
            headers: { 'content-type': 'application/x-www-form-urlencoded' }
          })
          .then(({ data }) => {
            if (data.status === 'success') {
              resolve()
              commit('updateFinancialYearStatus', form)
            } else {
              reject(new Error('Error closing financial year'))
            }
          })
          .catch(() => {
            reject(new Error('Error closing financial year'))
          })
      })
    },
    loadAgencyLogs ({ state }, { id, pagination }) {
      return new Promise((resolve, reject) => {
        const params = new URLSearchParams()
        if (id) {
          params.append('agency_id', id)
        }
        params.append('items', pagination.perPageItems)
        params.append('page', pagination.currentPage)

        axios.get(`${process.env.VUE_APP_ACTIVITYLOG_URL}activitylog?${params.toString()}`)
          .then(({ data }) => {
            resolve(data)
          })
      })
    },
    loadChart ({ commit }, year) {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_AGENCY_API_URL}consumption/yearly/${year}`)

          .then(({ data: { status, data } }) => {
            if (status === 'success') {
              const formatted = data.map(item => ({
                ...item,
                cleared: +item.cleared,
                commissioned: +item.commissioned
              }))
              resolve(formatted)
            } else {
              reject(new Error('Chart Data loading failed'))
            }
          })
          .catch(() => {
            reject(new Error('Chart Data loading failed'))
          })
      })
    },
    loadChartMobile ({ commit }, { month, year }) {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_AGENCY_API_URL}consumption/monthly/${parseFloat(month)}/year/${year}`)
          .then(({ data: { status, data } }) => {
            if (status === 'success') {
              resolve(data)
            } else {
              reject(new Error('Mobile Chart Data loading failed'))
            }
          })
          .catch(() => {
            reject(new Error('Mobile Chart Data loading failed'))
          })
      })
    },
    loadBudgetData ({ rootState }, id) {
      return new Promise((resolve, reject) => {
        let path = `${process.env.VUE_APP_AGENCY_API_URL}agency/budget`
        if ([realms.admin, realms.finance].includes(rootState.auth.realm)) {
          const params = new URLSearchParams()
          params.append('agency_id', id)
          path += `?${params.toString()}`
        }
        axios.get(path)
          .then(({ data: { status, data } }) => {
            if (status === 'success') {
              resolve(data)
            } else {
              reject(new Error('Budget Data loading failed'))
            }
          })
          .catch(() => {
            reject(new Error('Budget Data loading failed'))
          })
      })
    },
    updateUserRole ({ rootGetters, commit }, form) {
      return new Promise((resolve, reject) => {
        const params = new FormData()
        Object.keys(form).forEach(key => {
          params.append(key, form[key])
        })

        axios.post(`${process.env.VUE_APP_AGENCY_API_URL}role/switch`, params)
          .then(({ data: { status, data } }) => {
            if (status === 'success') {
              const isManagingDirector = rootGetters['auth/isManagingDirector']
              if (isManagingDirector) {
                commit('updateUserRole', form)
              }
              resolve()
            } else {
              reject(new Error('Role Update Failed'))
            }
          })
          .catch(() => {
            reject(new Error('Role Update Failed'))
          })
      })
    },
    loadUserAgencies ({ state, commit }) {
      if (loadingUserGroups) {
        return
      }
      loadingUserGroups = true
      axios.get(`${process.env.VUE_APP_AGENCY_API_URL}user/agency/list`)
        .then(({ data, status }) => {
          commit('setGroupsList', data?.data || [])
          loadingUserGroups = false
        }).catch(() => {
          loadingUserGroups = false
        })
    },
    loadMultiAgencyColleagues ({ commit }) {
      if (loadingColleagues) {
        return Promise.resolve()
      }
      loadingColleagues = true
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_AGENCY_API_URL}user/agency/members`)
          .then(({ data, status }) => {
            loadingColleagues = false
            commit('setMultiAgencyColleagues', data?.data || [])
            resolve()
          }).catch(() => {
            loadingColleagues = false
            reject(new Error('Error loading multiple agency users'))
          })
      })
    },
    checkFreelancerExists ({ commit }, email) {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_FREELANCER_API_URL}freelancer/email/${email}/exists`)
          .then(({ data }) => {
            resolve(data.data?.is_exists)
          })
          .catch(() => {
            resolve(false)
          })
      })
    },
    loadTopConsumptions ({ commit }, year) {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_AGENCY_API_URL}budget/consumption/top?financial_year=${year}`)
          .then(({ data: { status, data } }) => {
            if (status === 'success') {
              commit('setTopConsumptions', data)
              resolve()
            } else {
              reject(new Error('Error loading top consumptions'))
            }
          })
          .catch(() => {
            reject(new Error('Error loading top consumptions'))
          })
      })
    },
    loadSkills ({ commit }, search) {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_FREELANCER_API_URL}skill?keyword=${search.trim()}`)
          .then(({ data: { status, data } }) => {
            if (status === 'success') {
              resolve(data.data)
            } else {
              reject(new Error('Error loading skills'))
            }
          })
          .catch(() => {
            reject(new Error('Error loading skills'))
          })
      })
    },
    checkAgencyUserExists ({ commit }, email) {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_AGENCY_API_URL}user/${email}/exists`)
          .then(({ data }) => {
            resolve(data.data?.is_exists)
          })
          .catch(() => {
            resolve(false)
          })
      })
    },
    getBillingInfo ({ commit }) {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_AGENCY_API_URL}billing-info`)
          .then(({ data }) => {
            resolve(data.data)
          })
          .catch(() => {
            resolve(false)
          })
      })
    },
    setBillingInfo ({ commit }, { customerId, contractId }) {
      const params = new FormData()
      params.append('customer_id', customerId)
      params.append('contract_id', contractId)
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_AGENCY_API_URL}billing-info`, params)
          .then(({ data }) => {
            resolve(data.data)
          })
          .catch(() => {
            resolve(false)
          })
      })
    },
    updateCustomerContract ({ commit }, { planVariantId, quantity, contractId }) {
      return new Promise((resolve, reject) => {
        const params = new FormData()
        params.append('planVariantId', planVariantId)
        params.append('quantity', quantity)
        params.append('contractId', contractId)

        axios.post(`${process.env.VUE_APP_AGENCY_API_URL}update-customer-contract`, params,
          {
            headers: { 'content-type': 'application/x-www-form-urlencoded' }
          })
          .then(({ data }) => {
            if (data.status === 'success') {
              resolve(data.data)
            } else {
              reject(new Error('Error'))
            }
          })
          .catch(() => {
            reject(new Error('Error'))
          })
      })
    },
    getLicensesAvailable ({ commit }, { startDate, endDate, freelancerId }) {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_AGENCY_API_URL}licenses-available?start_date=` + startDate + '&end_date=' + endDate + '&freelancer_id=' + freelancerId)
          .then(({ data }) => {
            if (data.status === 'success') {
              resolve(data.data)
            } else {
              reject(new Error(data.message))
            }
          })
          .catch(() => {
            reject(new Error('Request Failed'))
          })
      })
    },
    canDeleteAgencyAccount () {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_AGENCY_API_URL}can-delete-agency`)
          .then(({ data }) => {
            if (data.status === 'success') {
              resolve()
            } else {
              reject(new Error('Error on validation to delete Agency account'))
            }
          })
          .catch(() => {
            reject(new Error('Error on validation to delete Agency account'))
          })
      })
    },
    deleteAgencyAccount () {
      return new Promise((resolve, reject) => {
        axios.delete(`${process.env.VUE_APP_AGENCY_API_URL}delete-agency`)
          .then(({ data }) => {
            if (data.status === 'success') {
              resolve()
            } else {
              reject(new Error('Error on deleting Agency account'))
            }
          })
          .catch(() => {
            reject(new Error('Error on deleting Agency account'))
          })
      })
    },
    getSelfServicePortalUrl () {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_AGENCY_API_URL}self-service-portal`)
          .then(({ data }) => {
            if (data.status === 'success') {
              resolve(data.data)
            } else {
              store.commit('addMessage', { messageKey: 'responseMessages.error.failedPortalUrl' })
              reject(data.message)
            }
          })
          .catch(() => {
            resolve(false)
          })
      })
    },
    isActive () {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_AGENCY_API_URL}is-active-agency`)
          .then(({ data }) => {
            if (data.status === 'success') {
              resolve(data.data)
            } else {
              reject(data.message)
            }
          })
          .catch(() => {
            resolve(false)
          })
      })
    }
  }
}
